<template>
  <v-list
    id="AppNavMenu"
    expand
  >
    <template v-for="(item, i) in availableMenuItems">
      <main-menu-group-item
        v-if="item.children"
        :key="`group-${i}`"
        :item="item"
        :mini="mini"
      >
        <!--  -->
      </main-menu-group-item>

      <main-menu-item
        v-else
        :key="`item-${i}`"
        :item="item"
        :mini="mini"
      />
    </template>
  </v-list>
</template>

<script>
  import MainMenuItem from './MainMenuItem'
  import MainMenuGroupItem from './MainMenuGroupItem'
  import Permission from '@/mixins/permission'

  export default {
    name: 'AppNavMenu',
    components: { MainMenuItem, MainMenuGroupItem },
    mixins: [Permission],
    props: {
      mini: Boolean,
    },
    data: () => ({
      expandProgramList: false,
      admins: [
        ['Management', 'people_outline'],
        ['Settings', 'settings'],
      ],
      mobileDevice: false,
      exitRequest: false,
    }),
    computed: {
      programId () {
        return this.$store.getters.programId
      },
      bgcolor1 () {
        return this.program.bgcolor1 ? this.program.bgcolor1 : '#000000'
      },
      bgcolor2 () {
        return this.program.bgcolor2 ? this.program.bgcolor2 : '#eaeaea'
      },
      selectedCompanyMini () {
        return this.program && this.program.name.slice(0, 1)
      },
      chatUnreadCount () {
        console.log('chatUnreadCount', this.$store.getters['chat/conversation/allUnreadCount'])
        return this.$store.getters['chat/conversation/allUnreadCount']
      },
      availableMenuItems () {
        return this.menuItems.filter(item => !item.hidden)
      },
      menuItems () {
        const _this = this
        return [
          {
            icon: '$iconify_ion-stats-chart',
            active_icon_color: 'secondary',
            title: this.$t('menu.dashboard'),
            to: '/dashboard',
            alias: ['/'],
            hidden: !this.userPermissionsSet.has('merchant-stat') && !this.authUser.is_super_admin,
          },
          {
            icon: '$iconify_ion-wallet-outline',
            title: this.$t('menu.loyalty_program'),
            to: '/loyalty',
            alias: ['/loyalty', 'ProgramBonusSetting', '/loyalty/setting'],
            hidden: !this.userPermissionsSet.has('cabinet-loyalty') && !this.authUser.is_super_admin,
          },
          {
            icon: '$iconify_ant-design-gift-outlined',
            title: this.$t('menu.certificates'),
            to: '/account/certificates',
            alias: ['/program/certificate/list', '/certificate/master'],
            hidden: !this.userPermissionsSet.has('program-certificate-read') && !this.authUser.is_super_admin,
            // children: [
            //   {
            //     icon: '$iconify_ant-design-gift-outlined',
            //     title: 'Сертификаты',
            //     to: '/account/certificates',
            //   },

            // ],
          },
          {
            icon: '$iconify_ion-funnel-outline',
            title: this.$t('menu.crm'),
            alias: ['/program/accounts', '/program/segments', '/program/sellings', '/program/sellings/new'],
            hidden: !this.hasAnyPermission(['program-account-list', 'program-account-create', 'program-sales-create']) && !this.authUser.is_super_admin,
            children: [
              {
                icon: '$iconify_feather-users',
                title: this.$t('menu.accounts'),
                to: '/program/accounts',
                hidden: !this.userPermissionsSet.has('program-account-create' || 'program-account-list') && !this.authUser.is_super_admin,
              },
              {
                icon: '$iconify_carbon-diagram',
                title: this.$t('menu.segments'),
                to: '/program/segments',
                hidden: !this.userPermissionsSet.has('program-client-segments') && !this.authUser.is_super_admin,
              },
              {
                icon: '$iconify_feather-shopping-bag',
                title: this.$t('menu.sales'),
                to: '/program/sellings',
                hidden: !this.userPermissionsSet.has('program-sales-read') && !this.authUser.is_super_admin,
              },
              {
                icon: 'mdi-cash-register',
                title: this.$t('menu.cash_register'),
                to: '/program/sellings/new',
                hidden: !this.userPermissionsSet.has('program-sales-create') && !this.authUser.is_super_admin,
              },
            ],
          },
          {
            icon: '$icons_promo',
            title: this.$t('menu.promotions'),
            to: '/program/promotions',
            alias: ['PromoList', 'PromoMaster'],
            hidden: !this.userPermissionsSet.has('program-bonus-offer-read') && !this.authUser.is_super_admin,
          },
          {
            icon: 'mdi-cart-outline',
            title: this.$t('menu.nomenclature'),
            hidden: !this.userPermissionsSet.has('program-nomenclature-read') && !this.authUser.is_super_admin,
            children: [
              {
                icon: '$iconify_feather-list',
                title: this.$t('menu.list'),
                to: '/nomenclature/list',
                alias: ['NomenclatureList', 'NomenclatureImportMaster'],
                hidden: !this.userPermissionsSet.has('program-nomenclature-read') && !this.authUser.is_super_admin,
              },
              {
                icon: '$iconify_carbon-diagram',
                title: this.$t('menu.segments'),
                to: '/nomenclature/segments',
                hidden: !this.userPermissionsSet.has('program-nomenclature-read') && !this.authUser.is_super_admin,
              },
            ],
          },
          {
            icon: '$iconify_ion-megaphone-outline',
            title: this.$t('menu.activity'),
            to: '/program/broadcasters',
            alias: ['EventBroadcastersList', 'EventBroadcasterMaster', 'EventBroadcasterForm'],
            hidden: !this.userPermissionsSet.has('program-broadcaster-read') && !this.authUser.is_super_admin,
          },
          {
            icon: '$iconify_ion-mail-outline',
            title: this.$t('menu.mailing'),
            to: '/mailing',
            alias: ['/mailing'],
            hidden: !this.userPermissionsSet.has('program-notification-read') && !this.authUser.is_super_admin,
          },
          {
            icon: '$iconify_ion-chatbox-outline',
            title: this.$t('menu.chats'),
            to: '/communications/chat',
            alias: ['ChatType', 'ChatConversation'],
            badge: _this.chatUnreadCount || null,
            hidden: !this.userPermissionsSet.has('program-chat') && !this.authUser.is_super_admin,
          },
          {
            icon: '$iconify_feather-settings',
            title: this.$t('menu.settings'),
            to: '/settings/menu',
            alias: [
              'SettingsIntegration',
              'SettingsRequisites',
              'SettingsStaff',
              'Moderations',
              '/settings/sendings',
              '/invitation-link',
              '/settings/chat',

            ],
            hidden: !this.userPermissionsSet.has('merchant-update') && !this.authUser.is_super_admin,
          },
        ]
      },      
      profile () {
        return this.$store.getters['profile/profile/profile']
      },
      programs () {
        return this.$store.getters['company/program/programs'].filter(item => item.id !== this.program.id)
      },
      program: {
        get () {
          return this.$store.getters['company/program/program']
        },
        set (v) {
          this.$store.commit('widget/filter/RESET_FILTER') // сброс фильтра бонусных операций
          this.$store.commit('company/program/SET_PROGRAM', v)
        },
      },
    },
    watch: {
      programId () {
        this.$store.dispatch('chat/conversation/getAllUnreadCount')
      },
    },
    mounted () {
      this.$store.dispatch('chat/conversation/getAllUnreadCount')
      console.log('userPermissions', this.userPermissions)
      console.log('userPermissionsSet', this.userPermissionsSet)
    },
    methods: {
      goToHelp () {
        this.$router.push({ name: 'Help' })
      },
      setExpandProgramList () {
        this.expandProgramList = !this.expandProgramList
      },
      goToMaster () {
        this.$router.push('/master')
        this.$refs.programListGroup.click()
      },
      changeCompany (item) {
        this.program = Object.assign({}, item)
        this.$refs.programListGroup.click()
      },

    },
  }
</script>

<style lang="scss" scoped>

// @import '@/styles/vuetify-preset-plus/overrides';

.menu-divider{
  border-color: #F2F2F7;
}

</style>
